<div class="container">

	<div class="ads-left-container">
		<app-ads class="ads" [ads]="leftSideAds" *ngIf="leftSideAds !== null"></app-ads>
	</div>
	<div class="ads-right-container">
		<app-ads class="ads" [ads]="rightSideAds" *ngIf="rightSideAds !== null"></app-ads>
	</div>

	<app-freshnews></app-freshnews>

	<ng-container *ngFor="let pageBlock of pageBlocks; let i = index;">
		<div class="block-selector-name" *ngIf="pageBlock.name !== ''">
			<div class="block-selector-name-inner" [ngStyle]="{'background-color': pageBlock.backgroundColor, 'color': pageBlock.textColor}">{{pageBlock.name}}</div>
		</div>
		<div class="block-selector-line"></div>

		<!-- EQUALS -->
		<div class="news-grid" [ngStyle]="{'grid-template-columns': getGridTemplateColumns(pageBlock)}" *ngIf="!isPageBlockEqual(pageBlock)">
			<div class="news-grid-column" *ngFor="let columnConfiguration of pageBlock.columnConfigurations">
				<ng-container *ngFor="let content of columnConfiguration.contents">
					<ng-container *ngIf="content.ads !== null">
						<app-ads [ads]="content.ads" [block]="true"></app-ads>
					</ng-container>
					<ng-container *ngIf="content.news !== null">
						<app-news
							[news]="content.news"
							[displaySubtitle]="content.displaySubtitle"
							[displayImage]="content.displayImage"
							[titleSize]="content.titleSize"
						></app-news>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<div class="news-grid" [ngStyle]="{'grid-template-columns': getGridTemplateColumns(pageBlock), 'grid-template-areas': getGridTemplateAreas(pageBlock)}" *ngIf="isPageBlockEqual(pageBlock)">
			<ng-container *ngFor="let columnConfiguration of pageBlock.columnConfigurations; let ci = index;">
				<ng-container *ngFor="let content of columnConfiguration.contents; let ri = index;">
					<ng-container *ngIf="content.ads !== null">
						<app-ads 
							[ads]="content.ads"
							[ngStyle]="{'grid-area': 'n' + ri.toString() + ci.toString()}"
						></app-ads>
					</ng-container>
					<ng-container *ngIf="content.news !== null">
						<app-news
							[news]="content.news"
							[displaySubtitle]="content.displaySubtitle"
							[displayImage]="content.displayImage"
							[titleSize]="content.titleSize"
							[ngStyle]="{'grid-area': 'n' + ri.toString() + ci.toString()}"
						></app-news>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>

	<!--
		<div class="news-grid" [ngStyle]="{'grid-template-columns': getGridTemplateColumns(pageBlock)}">
			<ng-container *ngIf="isPageBlockEqual(pageBlock)">
				<ng-container *ngFor="let c of pageBlock.columnConfigurations[0].contents; let ci = index;">
					<ng-container *ngFor="let cc of pageBlock.columnConfigurations; let cci = index">
						<ng-container *ngIf="pageBlock.columnConfigurations[cci].contents[ci].ads !== null">
							<app-ads [ads]="pageBlock.columnConfigurations[cci].contents[ci].ads"></app-ads>
						</ng-container>
						<ng-container *ngIf="pageBlock.columnConfigurations[cci].contents[ci].news !== null">
							<app-news
								[news]="pageBlock.columnConfigurations[cci].contents[ci].news"
								[displaySubtitle]="pageBlock.columnConfigurations[cci].contents[ci].displaySubtitle"
								[displayImage]="pageBlock.columnConfigurations[cci].contents[ci].displayImage"
								[titleSize]="pageBlock.columnConfigurations[cci].contents[ci].titleSize"
							></app-news>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!isPageBlockEqual(pageBlock)">
				<div class="news-grid-column" *ngFor="let columnConfiguration of pageBlock.columnConfigurations">
					<ng-container *ngFor="let content of columnConfiguration.contents">
						<ng-container *ngIf="content.ads !== null">
							<app-ads [ads]="content.ads"></app-ads>
						</ng-container>
						<ng-container *ngIf="content.news !== null">
							<app-news
								[news]="content.news"
								[displaySubtitle]="content.displaySubtitle"
								[displayImage]="content.displayImage"
								[titleSize]="content.titleSize"
							></app-news>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>-->

</div>

<div class="popup-ad" *ngIf="popupAdVisible && popupAds !== null">
	<div class="ad">
		<div class="ad-close" (click)="hidePopupAd()">X Bezárás</div>
		<app-ads [ads]="popupAds"></app-ads>
	</div>
</div>